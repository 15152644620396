import React from "react"
import { graphql } from "gatsby"
import Layout from "~/components/Layout"
import { buildBlocks } from "~/services/blockGenerator"

// @ts-ignore
const Page = ({ data, pageContext }) => {
  if (!data) {
    console.error("No data found for page")
    return null
  }
  const blocks = buildBlocks(data.wpPage.blocks)

  return (
    <Layout
      meta={data.wpPage.Meta}
      path={pageContext.pagePath}
      cta={data.wpPage.PageCTA}
      title={data.wpPage.title}
      themeOptions={data.wp.themeOptions}
      transHead={true}
    >
      {blocks}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Page
